<template>
  <div>
    <div>
        <el-button type="primary" size="small" @click="handle_add">
            新增
          </el-button>
    </div>
    <el-table :data="table_data" border style="width: 100%" height="500" stripe>
      <el-table-column prop="sys_name" label="系统名称" width="180" fixed></el-table-column>
      <el-table-column prop="env_addr" label="环境地址" width="180"></el-table-column>
      <el-table-column prop="tec_cont" label="技术联系人" width="180"></el-table-column>
      <el-table-column prop="login_step" label="校验登录步骤" width="180"></el-table-column>
      <el-table-column prop="test_round" label="测试轮次" width="180"></el-table-column>
      <el-table-column prop="scre_req" label="截图要求" width="180"></el-table-column>
      <el-table-column prop="remark" label="备注" width="180"></el-table-column>
      <!-- 操作栏 -->
       <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="handle_edit(scope.row)">
            编辑
          </el-button>
          <el-button type="primary" size="small" @click="del_table_data(scope.row)">
            删除
          </el-button>
        </template>
       </el-table-column>
    </el-table>
    <!-- 编辑会话 -->
    <el-dialog
        :title="dialog_title"
        :visible.sync="dialogVisible"
        width="50%">
        <el-form :model="form_data" label-width="90px">
            <el-form-item label="系统名称">
                <el-input v-model="form_data.sys_name"></el-input>
            </el-form-item>
            <el-form-item label="环境地址">
                <el-input v-model="form_data.env_addr"></el-input>
            </el-form-item>
            <el-form-item label="技术联系人">
                <el-input v-model="form_data.tec_cont"></el-input>
            </el-form-item>
            <el-form-item label="登录步骤">
                <el-input v-model="form_data.login_step"></el-input>
            </el-form-item>
            <el-form-item label="测试轮次">
                <el-input v-model="form_data.test_round"></el-input>
            </el-form-item>
            <el-form-item label="截图要求">
                <el-input v-model="form_data.scre_req"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form_data.remark"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitForm">提交</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            table_data: [], // 表格数据
            dialogVisible: false,
            dialog_title:'',
            form_data: {
                id:'',
                sys_name: '',
                env_addr: '',
                tec_cont: '',
                login_step: '',
                test_round: '',
                scre_req: '',
                remark: ''
            }
        };
    },
    mounted() {
        this.get_table_data();
    },    
    methods: {
        // 从数据库取数据
        async get_table_data() {
            try {
                const rsp = await this.$api.get('/get_table_data')
                this.table_data = Object.values(rsp);
            } catch (error) {
                console.log("请求失败:", error);
            }
        },
        // 点击新增按钮触发
        handle_add() {
            this.dialogVisible = true;
            this.dialog_title = "新增";
            this.form_data = {
                id: '',
                sys_name: '',
                env_addr: '',
                tec_cont: '',
                login_step: '',
                test_round: '',
                scre_req: '',
                remark: ''
            };
        },
        // 删除数据
        async del_table_data(row_data) {
            try {
                const rsp = await this.$api.post('/del_table_data',row_data);
                this.$alert(rsp, '删除', { type: 'success', confirmButtonText: '确定' })
                this.get_table_data()
            } catch (error) {
                console.log("请求失败:", error);
            }
        },
        handle_edit(row) {
            //打开dialog
            this.dialog_title = "编辑"
            this.dialogVisible = true;
            this.form_data = {...row};
        },
        async submitForm() {
            // 新增操作
            if (this.dialog_title === "新增") {
                try {
                    const add_rsp = await this.$api.post('/add_test_env_data', this.form_data);
                    this.$alert(add_rsp, "新增", { type: 'success' }) 
                    this.dialogVisible = false
                    this.get_table_data()
                } catch (error) {
                    this.$alert(error,'新增',{type:'error'})
                }
            } else if (this.dialog_title === "编辑"){   //修改操作
                try {
                const sub_rsp = await this.$api.post('/mod_test_env_data',this.form_data); 
                this.$alert(
                    sub_rsp,
                    '提交',
                    {
                        type: 'success',
                        confirmButtonText: '确定',
                    },); 
                this.get_table_data();
                    this.dialogVisible = false;
                } catch (error) {
                    console.log("请求失败:", error);
                }    
            }
        }
    }
};
</script>

<style scoped>
.el-table {
  margin-top: 20px;
}
</style>