<template>
    <div>
        <h2 style="text-align: center;">定期存款</h2>
        <table class="table">
            <!-- 本金相关 -->
            <tr>
                <td class="ps">存款本金：</td>
                <td class="input">
                    <el-input placeholder="请输入存款金额" v-model="DepositAmount">
                        <template v-slot:append>元</template>
                    </el-input>
                </td>
            </tr>
            <tr>
                <td class="ps">存款期限：</td>
                <td>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td class="ps">存款利率：</td>
                <td>
                    <el-input placeholder="请输入内容" v-model="InterestRate">
                        <template slot="append">%</template>
                    </el-input>
                </td>
            </tr>
        </table>
        <!-- 输入 -->
        <div class="inputcontainer">
            <!-- 计息按钮 -->
            <div>
                <el-row class="button">
                    <el-button type="primary" @click="calculate">计算</el-button>
                    <el-button type="warning" @click="reset">重置</el-button>
                </el-row>
            </div>
            <!-- 利息展示 -->
            <div class="interestDis">
                <el-input v-model="TotalInterest" class="display" readonly>
                    <template slot="prepend">利息总额：</template>
                    <template slot="append">元</template>
                </el-input>
                <el-input v-model="TotalAll" class="display" readonly>
                    <template slot="prepend">本息合计：</template>
                    <template slot="append">元</template>
                </el-input>
            </div>
            <!-- 介绍 -->
            <div>
                <h3>基本介绍</h3>
                <h4>定期存款</h4>
                <ul>定期存款是指银行与存款人双方在存款时事先约定期限、利率，到期后支取本息的存款，定期存款通常分为三个月、半年、一年、二年、三年、五年六个利率档次，和活期存款相比，定期存款具有较强的稳定性。</ul>
                <h4>利息计算</h4>
                <h5>提前销户</h5>
                <ul>应付利息=提前支取本金×利率(年)/360×实际存期(日)</ul>
                <ul>应付利息=提前支取本金×利率(年)/365×实际存期(日)-港币、英镑</ul>
                <ul>应付本息和＝本金+应付利息</ul>
                <ul>提前支取本金利率按支取日个人储蓄活期存款利率计付利息</ul>
                <h5>部分提前支取</h5>
                <ul>应付利息=提前支取本金×利率(年)/360×实际存期(日)</ul>
                <ul>提前支取本金利率按支取日个人储蓄活期存款利率计付利息；实际存期为起息日到支取日实际天数；其余部分本金到期时按起息日挂牌公告的个人整存整取定期储蓄存款利率计付利息</ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            DepositAmount: '',    //金额
            DepositDuration: '',  //存期
            InterestRate: '',     //利率
            TotalInterest: '', //利息
            TotalAll: '',         //本息
            options: [
                { value: '0.25', label: '3个月' },
                { value: '0.5', label: '6个月' },
                { value: '1', label: '1年' },
                { value: '2', label: '2年' },
                { value: '3', label: '3年' },
                { value: '5', label: '5年' },
            ],
            value: '0.25',
        }
    },
    methods: {
        // 利息重置
        reset() {
            this.TotalInterest = '';
            this.TotalAll = '';
        },
        // 计算利息和本息合计
        calculate() {
            // 检查输入是否为空
            if (!this.DepositAmount || !this.value || !this.InterestRate) {
                this.$message.error('请输入完整的存款信息！');
                return;
            }

            // 将输入转换为数值
            const principal = parseFloat(this.DepositAmount); // 存款本金
            const duration = parseFloat(this.value); // 存期（年）
            const rate = parseFloat(this.InterestRate) / 100; // 利率（百分比转换为小数）

            // 计算利息
            const interest = principal * duration * rate;

            // 计算本息合计
            const total = principal + interest;

            // 更新数据
            this.TotalInterest = interest.toFixed(2); // 保留两位小数
            this.TotalAll = total.toFixed(2); // 保留两位小数
        },
    }
}
</script>


<style>
/* 表格 */
.table {
    width: 100%;
}

.ps {
    width: 8%;
}

.input {
    width: 92%;
}


.button {
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

/* 利息展示 */
.interestDis {
    font-weight: bold;
}

.display {
    text-align: right;
}

/* 调整日期选择器的样式 */
</style>