<template>
  <div>
    <h2 style="text-align: center;">利息计算器</h2>
    <table class="table">
      <!-- 本金相关 -->
      <tr>
        <td class="ps">存款本金：</td>
        <td class="input">
          <el-input placeholder="请输入存款金额" v-model="DepositAmount">
            <template v-slot:append>元</template>
          </el-input>
        </td>
      </tr>
      <!-- 存期相关 -->
      <tr>
        <td class="ps">存款期限：</td>
        <td>
          <el-date-picker v-model="date_range" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </td>
      </tr>
      <tr>
        <td class="ps">计息方式：</td>
        <td>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td class="ps">存款利率：</td>
        <td>
          <el-input placeholder="请输入存款利率" v-model="InterestRate">
            <template slot="append">%</template>
          </el-input>
        </td>
      </tr>
    </table>
    <!-- 输入 -->
    <div class="inputcontainer">
      <!-- 计息按钮 -->
      <div>
        <el-row class="button">
          <el-button type="primary" @click="calculate">计算</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-row>
      </div>
      <!-- 利息展示 -->
      <div class="interestDis">
        <el-input v-model="TotalInterest" class="display" readonly>
          <template slot="prepend">利息总额：</template>
          <template slot="append">元</template>
        </el-input>
        <el-input v-model="TotalAll" class="display" readonly>
          <template slot="prepend">本息合计：</template>
          <template slot="append">元</template>
        </el-input>
      </div>
      <!-- 介绍 -->
      <div>
        <h3>基本介绍</h3>
        <ul>本金：即用户计划存入的资金总额。</ul>
        <ul>利率：代表用户可获得的利息比例，常以百分比形式展现。</ul>
        <ul>期限：存款的持续时间，可根据具体情境以不同时间单位计量。</ul>
        <h3>输出结果</h3>
        <ul>总利息：在贷款或存款周期内累积产生的利息总和。</ul>
        <ul>总收益：支取时能够获得的本金+利息。例如：存款1万元，存款期限为2年，若按照基准利率来计算，3年到期获得的利息为：10000 × 2.10% × 2 = 420 元</ul>
        <ul></ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DepositAmount: '',    //金额
      date_range: '',        //日期选择器
      DepositDuration: '',  //存期
      InterestRate: '',     //利率
      TotalInterest: '', //利息
      TotalAll: '',         //本息
      options: [{
        value: '360',
        label: 'A/360'
      }, {
        value: '365',
        label: 'A/365'
        }],
      value: '360',
      show_data:'',
    }
  },
  methods: {
    // 利息重置
    reset() {
      this.TotalInterest = '';
      this.TotalAll = '';
    },
    calculate() {
      if (!this.DepositAmount || !this.date_range || !this.InterestRate) {
        this.$message.warning('请填写完整信息');
        return;
      }

      // 确保所有输入都是有效的数字
      const principal = parseFloat(this.DepositAmount) || 0; // 本金，确保是数字，如果转换失败则默认为0
      const rate = parseFloat(this.InterestRate) / 100 || 0; // 利率，转换为小数，如果转换失败则默认为0
      const daysInYear = parseInt(this.value, 10) || 365; // 计息天数，默认为365天，确保是数字

      // 计算存款天数
      const startDate = new Date(this.date_range[0]);
      const endDate = new Date(this.date_range[1]);
      const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // 直接得到天数差，不需要Math.ceil，因为天数差应该是浮点数（如果考虑时间部分）
      // 但由于我们通常按整天计算，所以这里可以取整：Math.floor(daysDiff) 或 Math.ceil(daysDiff) 根据实际需求选择
      // 这里为了简化，我们假设只考虑整天，且使用Math.floor向下取整（即不包含结束日期的那一天）
      const actualDaysDiff = Math.floor(daysDiff);

      // 如果天数为0或负数（虽然逻辑上不应该出现），则直接返回
      if (actualDaysDiff <= 0) {
        this.TotalInterest = 0;
        this.TotalAll = principal.toFixed(2);
        return;
      }

      // 计算利息和本息
      const interest = (principal * rate * actualDaysDiff) / daysInYear;
      const total = principal + interest;

      // 输出结果，保留两位小数
      this.TotalInterest = interest.toFixed(3);
      this.TotalAll = total.toFixed(3);
    },
  }
}
</script>


<style>
/* 表格 */
.table{
  width: 100%;
}
.ps{
  width: 8%;
}
.input{
  width:92%;
}


.button {
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

/* 利息展示 */
.interestDis {
  font-weight: bold;
}

.display {
  text-align: right;
}

/* 调整日期选择器的样式 */

</style>