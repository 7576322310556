<template>
  <div class="parent">
    <!-- tab切换 -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="利息计算器" name="first"></el-tab-pane>
      <el-tab-pane label="定期存款" name="second"></el-tab-pane>
      <el-tab-pane label="存本取息" name="third"></el-tab-pane>
      <el-tab-pane label="证件信息生成" name="forth"></el-tab-pane>
      <el-tab-pane label="表格" name="fifth"></el-tab-pane>
      <el-tab-pane label="证件图片生成" name="sixth"></el-tab-pane>
    </el-tabs>
    <component :is="currentComponent" />
  </div>
</template> 
<script>
// 引入组件
import InterestCalculate from './components/deposit_comp/InterestCalculate.vue'
import fixed_deposit from './components/deposit_comp/fixed_deposit.vue'
import dai_ding from './components/deposit_comp/dai_ding.vue'
import cus_info_gen from './components/deposit_comp/cus_info_gen.vue'
import table_stu from './components/deposit_comp/table_stu.vue'
import bus_licen from './components/img_info_gen.vue/bus_licen.vue'
export default {
  components:{
    InterestCalculate,
    fixed_deposit,     
    dai_ding,
    cus_info_gen,
    table_stu,
    bus_licen
  },
  data(){
    return{
      activeName:'first',   //tab
    }
  },
  // 根据 activeTab 的值动态返回对应的组件
  computed: {
    currentComponent() {
      switch (this.activeName) {
        case 'first':
          return InterestCalculate;
        case 'second':
          return fixed_deposit;
        case 'third':
          return dai_ding;
        case 'forth':
          return cus_info_gen;
        case 'fifth':
          return table_stu;
        case 'sixth':
          return bus_licen;
        default:
          return null;
      }
    },
  },
  methods:{
    handleClick(tab, event) {
        console.log(tab, event);
    }
  }
}
</script>

<style scoped>
    .parent{
      width: 80%;
      margin: 0 auto;
      margin-top: 10%;
    }
</style>