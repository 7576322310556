// src/api/index.js
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://127.0.0.1:5000/img_gen',
  baseURL: 'https://back.freestudys.com/img_gen/',
  timeout: 5000,
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做一些处理，比如添加 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做一些处理
    return response.data;
  },
  error => {
    // 对响应错误做一些处理
    return Promise.reject(error);
  }
);

export default instance;