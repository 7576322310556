import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from "@/api";      // 导入封装的 axios 实例

Vue.config.productionTip = false
Vue.use(ElementUI);
// // 挂载到 Vue 原型上
Vue.prototype.$api = api;

new Vue({
  render: h => h(App),
}).$mount('#app')
