<template>
    <div>
        <table class="table">
            <!-- 生成证件号码 -->
            <tr>
                <!-- 出生日期 -->
                <td style="width: 30%;">
                    出生日期：
                    <el-date-picker v-model="date" type="date" placeholder="选择日期"></el-date-picker>
                </td>
                <!-- 性别 -->
                <td style="width: 10%;">
                    性别：
                    <el-select v-model="gender" placeholder="请选择">
                        <el-option v-for="item in gender_type" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </td>
                <!-- 证件类型 -->
                <td style="width: 10%;">
                    证件类型：
                    <el-select v-model="card" placeholder="请选择">
                        <el-option v-for="item in card_type" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </td>
                <td style="width: 10%;">
                    <el-button type="primary" @click="gen_id_num">生成</el-button>
                </td>
                <!-- 身份证号展示 -->
                <td>
                    <el-input v-model="idNumber" placeholder="身份证号" readonly></el-input>
                </td>
            </tr>
            <!-- 生成港澳居民来往内地通行证 -->
            <tr>
                <td style="width: 30%;">
                    选择地区：
                    <el-select v-model="hmtRegion" placeholder="请选择">
                        <el-option v-for="item in hmtOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </td>
                <td style="width: 20%;">
                </td>
                <td>
                    <el-button type="primary" @click="gen_hmt_pass">生成港澳居民来往内地通行证</el-button>
                </td>
                <td>
                    <el-input v-model="hmtPassNumber" placeholder="港澳居民来往内地通行证" readonly></el-input>
                </td>
            </tr>
            <!-- 生成台湾居民来往大陆通行证 -->
            <tr>
                <td style="width: 30%;">
                    <el-button type="primary" @click="gen_tw_pass">生成台湾居民来往大陆通行证</el-button>
                </td>
                <td>
                    <el-input v-model="twPassNumber" placeholder="台湾居民来往大陆通行证" readonly></el-input>
                </td>
            </tr>
            <!-- 生成护照 -->
            <tr>
                <td style="width: 30%;">
                    护照类型：
                    <el-select v-model="passportType" placeholder="请选择">
                        <el-option v-for="item in passportOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </td>
                <td>
                    <el-button type="primary" @click="gen_passport">生成护照</el-button>
                </td>
                <td>
                    <el-input v-model="passportNumber" placeholder="护照号码" readonly></el-input>
                </td>
            </tr>
            <!-- 生成外国人永久居留证 -->
            <tr>
                <td style="width: 30%;">
                    证件类型：
                    <el-select v-model="permitType" placeholder="请选择">
                        <el-option v-for="item in permitOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </td>
                <td>
                    <el-button type="primary" @click="gen_permit">生成外国人永久居留证</el-button>
                </td>
                <td>
                    <el-input v-model="permitNumber" placeholder="外国人永久居留证" readonly></el-input>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            date: '', // 出生日期
            gender: '', // 性别
            idNumber: '', // 生成的身份证号
            gender_type: [
                { value: 'male', label: '男性' },
                { value: 'female', label: '女性' },
            ],
            card:'',
            card_type: [
                { value: 0, label: "身份证"},
                { value: 1, label: "港澳居民来往内地通行证" },
                { value: 2, label: "台湾居民来往大陆通行证" },
                { value: 3, label: "护照" },
                { value: 4, label: "外国人永久居留证" },
                
            ],
            hmtRegion: '', // 港澳地区
            hmtPassNumber: '', // 港澳居民来往内地通行证
            hmtOptions: [
                { value: 'H', label: '香港居民' },
                { value: 'M', label: '澳门居民' },
            ],
            twPassNumber: '', // 台湾居民来往大陆通行证
            passportType: '', // 护照类型
            passportNumber: '', // 护照号码
            passportOptions: [
                { value: 'E', label: '普通因私护照' },
                { value: 'P', label: '公务护照' },
                { value: 'D', label: '外交护照' },
            ],
            permitType: '', // 外国人永久居留证类型
            permitNumber: '', // 外国人永久居留证
            permitOptions: [
                { value: 'C', label: '永久居留证' },
                { value: 'D', label: '临时居留证' },
            ],
        };
    },
    methods: {
        // 生成身份证号
        gen_id_num() {
            if (!this.date || !this.gender) {
                this.$message.warning('请选择出生日期和性别');
                return;
            }

            // 1. 前6位地区码（固定值）
            const areaCode = '110105'; // 北京市朝阳区

            // 2. 出生日期部分（YYYYMMDD）
            const year = this.date.getFullYear();
            const month = String(this.date.getMonth() + 1).padStart(2, '0');
            const day = String(this.date.getDate()).padStart(2, '0');
            const birthDate = `${year}${month}${day}`;

            // 3. 生成顺序码（第15-17位）
            let sequenceCode = Math.floor(Math.random() * 999).toString().padStart(3, '0');

            // 根据性别调整顺序码的最后一位
            if (this.gender === 'male') {
                // 男性：最后一位必须是奇数（1, 3, 5, 7, 9）
                sequenceCode = sequenceCode.slice(0, 2) + String(Math.floor(Math.random() * 5) * 2 + 1);
            } else if (this.gender === 'female') {
                // 女性：最后一位必须是偶数（0, 2, 4, 6, 8）
                sequenceCode = sequenceCode.slice(0, 2) + String(Math.floor(Math.random() * 5) * 2);
            }

            // 4. 组合前17位
            const idNumber17 = areaCode + birthDate + sequenceCode;

            // 5. 计算校验码
            const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

            let sum = 0;
            for (let i = 0; i < 17; i++) {
                sum += parseInt(idNumber17[i]) * weights[i];
            }

            const checkCode = checkCodes[sum % 11];

            // 6. 生成完整身份证号
            this.idNumber = idNumber17 + checkCode;
        },

        // 生成港澳居民来往内地通行证
        gen_hmt_pass() {
            if (!this.hmtRegion) {
                this.$message.warning('请选择地区');
                return;
            }

            // 1. 生成8位数字
            const randomNumber = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');

            // 2. 组合前9位
            const hmtPass9 = this.hmtRegion + randomNumber;

            // 3. 计算校验码
            const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

            let sum = 0;
            for (let i = 0; i < 9; i++) {
                sum += parseInt(hmtPass9[i]) * weights[i];
            }

            const checkCode = checkCodes[sum % 11];

            // 4. 生成完整号码
            this.hmtPassNumber = hmtPass9 + checkCode;
        },

        // 生成台湾居民来往大陆通行证
        gen_tw_pass() {
            // 1. 生成8位数字
            const randomNumber = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');

            // 2. 组合前8位
            const twPass8 = randomNumber;

            // 3. 计算校验码
            const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

            let sum = 0;
            for (let i = 0; i < 8; i++) {
                sum += parseInt(twPass8[i]) * weights[i];
            }

            const checkCode = checkCodes[sum % 11];

            // 4. 生成完整号码
            this.twPassNumber = twPass8 + checkCode;
        },

        // 生成护照
        gen_passport() {
            if (!this.passportType) {
                this.$message.warning('请选择护照类型');
                return;
            }

            // 1. 生成8位数字
            const randomNumber = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');

            // 2. 组合完整号码
            this.passportNumber = this.passportType + randomNumber;
        },

        // 生成外国人永久居留证
        gen_permit() {
            if (!this.permitType) {
                this.$message.warning('请选择证件类型');
                return;
            }

            // 1. 生成15位数字
            const randomNumber = Math.floor(Math.random() * 1000000000000000).toString().padStart(15, '0');

            // 2. 组合完整号码
            this.permitNumber = this.permitType + randomNumber;
        },
    },
};
</script>

<style scoped>
.table {
    width: 100%;
}
</style>