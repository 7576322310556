<template>
  <div>
    <!-- 表单 -->
    <el-form :model="formData" label-width="130px">
      <el-form-item label="统一社会信用代码" >
        <el-input v-model="formData.comp_id" style="width:70%;"></el-input>
        <el-select v-model="value" placeholder="请选择" @change="id_type_change">
          <el-option
            v-for="item in id_type_op"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>  
      <el-form-item label="证照编号">
        <el-input v-model="formData.cer_number"></el-input>
      </el-form-item>  
      <el-form-item label="公司名称">
        <el-input v-model="formData.comp_name"></el-input>
      </el-form-item>
      <el-form-item label="公司类型">
        <el-input v-model="formData.comp_type"></el-input>
      </el-form-item>
      <el-form-item label="法定代表人">
        <el-input v-model="formData.legal_representative"></el-input>
      </el-form-item>
      <el-form-item label="经营范围">
        <el-input v-model="formData.business_scope" type="textarea"></el-input>
      </el-form-item>
      <!-- 证书右边 -->
      <el-form-item label="注册资本">
        <el-input v-model="formData.regis_capital"></el-input>
      </el-form-item>
      <el-form-item label="成立日期">
        <el-date-picker v-model="formData.establishment_date" type="date"></el-date-picker>
      </el-form-item>
      <el-form-item label="营业期限">
        <el-date-picker 
            v-model="formData.business_term" 
            type="daterange" 
            range-separator="至" 
            start-placeholder="开始日期" 
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="住所">
        <el-input v-model="formData.live_addr" style="width: 80;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="generateImage">生成营业执照</el-button>
      </el-form-item>
    </el-form>

    <!-- 生成的图片 -->
    <div v-if="imageUrl">
      <img :src="imageUrl" alt="营业执照" style="max-width: 100%;" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.id_type_change(0)
  },
  data() {
    return {
      value: 0,
      id_type_op: [
        { value: 0, label: "随机"},
        { value: 1, label: "91-企业" },
        { value: 2, label: "92-个体工商户"}
      ],
      formData: {
        comp_id: '91320106MA1RA5EXXX',
        cer_number:'01000002201908280013',
        comp_name: '前轱辘不转后轱辘转有限责任公司',
        comp_type: '有限责任公司',
        legal_representative: '唐霸天',
        business_scope: '俺也不知道做啥，反正啥都做呗',
        regis_capital: '1500万元',
        establishment_date: "2025-03-03T16:00:00.000Z",
        business_term: ["2025-03-11T16:00:00.000Z", "2025-03-20T16:00:00.000Z"],
        live_addr: '萧山区XX街道浙江XX银行',
      },
        imageUrl: '', // 生成的图片 URL
    };
  },
  methods: {
    async generateImage() {
      try {
        // 发送请求生成图片
        const response = await this.$api.post('/generate', this.formData);
        // this.imageUrl = response.imageUrl;
        this.imageUrl = `${response.imageUrl}?t=${new Date().getTime()}`;
      } catch (error) {
      // 错误处理
        this.$message.error('生成图片失败', error);
      }
    },
    id_type_change(value) {
      // 符合规范的字符集
        const CHAR_SET = '0123456789ABCDEFGHJKLMNPQRTUWXY'; 
      // 计算校验码
        const weightedFactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];
      if (value === 0) {
        let code = '';
        // 随机生成前17位
        for (let i = 0; i < 17; i++) {
          code += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
        }
        let total = 0;
        for (let i = 0; i < code.length; i++) {
          total += CHAR_SET.indexOf(code[i]) * weightedFactors[i];
        }
        const logicCheckCode = 31 - (total % 31);
        const checkDigit = logicCheckCode === 31 ? '0' : CHAR_SET[logicCheckCode];
        this.formData.comp_id = code + checkDigit;
      } else if (value === 1) {
        let code = 91; // 固定前缀
        // 随机生成除前缀外的其他15位
        for (let i = 0; i < 15; i++) {
          code += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
        }
        let total = 0;
        for (let i = 0; i < code.length; i++) {
          total += CHAR_SET.indexOf(code[i]) * weightedFactors[i];
        }
        const logicCheckCode = 31 - (total % 31);
        const checkDigit = logicCheckCode === 31 ? '0' : CHAR_SET[logicCheckCode];
        this.formData.comp_id = code + checkDigit;
      } else if (value === 2) {
        let code = 92; // 固定前缀
        // 随机生成除前缀外的其他15位
        for (let i = 0; i < 15; i++) {
          code += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
        }
        let total = 0;
        for (let i = 0; i < code.length; i++) {
          total += CHAR_SET.indexOf(code[i]) * weightedFactors[i];
        }
        const logicCheckCode = 31 - (total % 31);
        const checkDigit = logicCheckCode === 31 ? '0' : CHAR_SET[logicCheckCode];
        this.formData.comp_id = code + checkDigit;
      }
    }
  },
};
</script>